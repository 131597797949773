import React from 'react'
import FestifHelmet from '../../components/Helmet/FestifHelmet'
import Wolflayout from '../../components/wolflayout'
import InfosHeader from '../../components/Infos/InfosHeader'
import InfosNavigation from '../../components/Infos/InfosNavigation'

const Reglements = () => {
  const metaTitle = 'Règlements | Le Festif! de Baie-Saint-Paul'
  const metaDescription = 'Objets permis ou interdits sur les sites du festival et règlement concernant les animaux domestiques'
  const metaKeywords = 'festival, musique, événement, spectacles, québec, charlevoix, règlements, interdictions'

  return (
    <Wolflayout mobileTitle={'Infos'}>
      <FestifHelmet title={metaTitle} description={metaDescription} keywords={metaKeywords} />
      <InfosNavigation />
      <InfosHeader
        title="Règlements"
      />
      <div className="container content pb-12">

        <h2>Tu t’interroges à savoir ce que tu peux ou ne peux pas apporter sur les sites du festival? Consulte attentivement les deux listes qui suivent.</h2>

        <h3>Items interdits</h3>
        <ul className="!list-outside md:!px-[80px] !px-[30px]">
          <li>Boissons alcoolisées (les verres Écocups doivent être vidés avant l'entrée sur les sites. Les bouteilles de boisson non alcoolisée devront être scellées, sans quoi elles seront vérifiées).</li>
          <li>Drogues illégales et accessoires reliés (à l’exception de médicaments sous prescription)</li>
          <li>Bouteilles en verre et canettes (fermées ou ouvertes)</li>
          <li>Glacière, thermos ou bouteille d’eau en aluminium</li>
          <li>Feux d’artifices et pétards</li>
          <li>Accessoires à clou (studs)</li>
          <li>Balles de fusil</li>
          <li>Armes blanches et objets contondants</li>
          <li>Bâtons</li>
          <li>Tout item destiné à la vente ou à la promotion distribué sans une permission écrite et signée</li>
          <li>Mégaphones</li>
          <li>Grands sac-à-dos de style camping</li>
          <li>Tentes</li>
          <li>Enregistreuses audio avec micro</li>
          <li>Caméra professionnelle (équipement de captation) et flash externe</li>
          <li>Drone</li>
          <li>Skateboard, trottinette et vélo</li>
          <li>Tout instrument de musique (spécialement les tambours)</li>
          <li>Animaux (sauf les chiens d'assitance)</li>
          <li>Chaises pliantes</li>
          <li>Tout item pouvant poser un danger à la sécurité des autres.</li>
          <li>Parapluies</li>
        </ul>

        <h3>Items acceptés</h3>
        <ul className="!list-outside md:!px-[80px] !px-[30px]">
          <li>Poussettes pour enfant et biberons</li>
          <li>Serviettes de plage</li>
          <li>Sacs à dos et sacoches de petit format (Tous les sacs et sacoches seront fouillés)</li>
          <li>Jumelles</li>
          <li>Nourriture (collations légères)</li>
          <li>Bouteilles d'eau en plastique dur et en plastique mou</li>
          <li>Bouteilles en plastique froissable (jetable) ou de style "camelbak" contenant de l'eau, des boissons gazeuses ou du jus. Celles-ci doivent être débouchées avant l'entrée sur le site.</li>
          <li>Verre Écocup vide</li>
        </ul>

        <h3>Animaux interdits</h3>
        <p>Par souci pour leur bien-être, le Festif! interdit l’accès à ses sites de spectacles aux chiens et autres animaux de compagnie, sauf les chiens d'assistance.</p>

      </div>
    </Wolflayout>
  )
}

export default Reglements
